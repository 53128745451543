import { useState } from "react";
import { Input, Container, Box, Button } from '@chakra-ui/react'

const api = (window.location.hostname === "localhost")? "http://localhost:5000" : "https://ezrv7ph736ygrppbz7npqmjfmi0aewws.lambda-url.eu-north-1.on.aws";

function Post() {
	const [query, setQuery] = useState("");
	const [title, setTitle] = useState("");
	const [desc , setDesc]  = useState("");
	const [img  , setImg]   = useState("");

	const queryParams = new URLSearchParams(window.location.search);
	const key = queryParams.get("key");

	const getQuery = async() => {
		const response = await fetch(api + "/rss_get_query");
		if (!response.ok)
			throw new Error("Network error");
		const data = await response.text();
		setQuery(data);
		setTitle("");
		setDesc("");
	};

	const updateQuery = async() => {
		const response = await fetch(api + "/rss_update_query?q=" + query);
		if (!response.ok)
			throw new Error("Network error");
		const data = await response.text();
		setQuery(data);
		setTitle("");
		setDesc("");
	};

	const getFluff = async() => {
		setTitle("");
		setDesc("");

		const responset = await fetch(api + "/rss_get_title?q=" + query);
		if (!responset.ok)
			throw new Error("Network error");
		const title = await responset.text();
		setTitle(title);

		const responsed = await fetch(api + "/rss_get_desc?title=" + title);
		if (!responsed.ok)
			throw new Error("Network error");
		const desc = await responsed.text();
		setDesc(desc);
	};

	const publish = async() => {
		const response = await fetch(api + "/create_rss_post?q=" + query + "&key=" + key + "&title=" + title + "&description=" + desc);
		if (!response.ok)
			throw new Error("Network error");
		const data = await response.text();
		setImg(data);
		console.log("Done");
	}

	const remove = async() => {
		const response = await fetch(api + "/delete_rss_post?q=" + query + "&key=" + key);
		if (!response.ok)
			throw new Error("Network error");
		setImg("");
	}

	const ts = () => {
		return (new Date().getTime());
	}

	return (
      	<Box bg="background" width="100%" minH="100vh">
	      	<Container minW="sm" width="70%" centerContent minH="calc(100vh - 4em)">
	      		<Input value={query} onChange={(e) => {e.preventDefault(); setQuery(e.target.value)}}/>
	      		<Button onClick={getQuery}>Get query</Button>
	      		<Button onClick={updateQuery}>Update query</Button>
	      		<Input value={title} onChange={(e) => {e.preventDefault(); setTitle(e.target.value)}}/>
	      		<Input value={desc} onChange={(e) => {e.preventDefault(); setDesc(e.target.value)}}/>
	      		<Button onClick={getFluff}>Get fluff</Button>
	      		<Button onClick={publish}>Publish</Button>
	      		<Button onClick={remove}>Delete</Button>

	      		 <Box as="div"
		        	style={{
						backgroundImage: `url(${img}?dt=${ts()})`,
						backgroundPosition: "left top",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						minWidth: "1000px",
						minHeight: "1500px",
					}}>
		        </Box>

	      	</Container>
	    </Box>);
}

export default Post;


