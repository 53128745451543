import React from 'react';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ReactGA from "react-ga4";

import Search from './pages/search.jsx';
import PrivacyPolicy from './pages/privacy_policy.jsx';
import TermsConditions from './pages/terms_conditions.jsx';
import About from './pages/about.jsx';
import Prompts from './pages/prompts.jsx';
import Post from './pages/_post.jsx';

ReactGA.initialize("G-WDY0HHJ1W9");

const theme = extendTheme({
	colors: {
		heading: "#7C90A0",
		text: "#534B52",
		background: "#F5F5F5",
	},
	fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
  },
});

const add_canonical = () => {
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== "www")
        return;

    const pathWithParams = window.location.pathname + window.location.search;
    const url = "https://readivate.com" + pathWithParams;

    return url;
    return (<link rel="canonical" href={url}/>);
}
  
function App() {
    return (
    	<ChakraProvider theme={theme}>
    		<HelmetProvider>
                <Helmet>
                    {add_canonical()}
               </Helmet>
                <Router>
                    <Routes>
                        <Route exact path='/' exact element={<Search/>}/>
                        <Route path='/privacy_policy' element={<PrivacyPolicy/>}/>
                        <Route path='/terms_conditions' element={<TermsConditions/>}/>
                        <Route path="/prompts" element={<Prompts/>}/>
                        <Route path='/about' element={<About/>}/>
                        <Route path='/_post' element={<Post/>}/>
                    </Routes>
                </Router>
            </HelmetProvider>
        </ChakraProvider>
    );
}
  
export default App;