import React from "react";
import { Container, Box, Text, Heading, Flex, Link } from "@chakra-ui/react";
  
const About = () => {
  const title = "Readivate";

  return (
    <Box bg="background" width="100%" minH="100vh">
    <Container minW="m" width="70%" centerContent minH="calc(100vh - 4em)" paddingTop="5em">
      <Heading color="heading">About</Heading>
      <Text color="text" p="2">
        {title} is a book and audiobook recommendation site powered by advanced AI technology. Our mission is to match specific books with specific user queries, offering a personalized and efficient search experience.
      </Text>
      <Text color="text" p="2">
        We created {title} because we were unsatisfied with the traditional genre-based recommendation systems that limit the user's options. Instead, we opted for a freeform search approach that allows users to refine and specify their queries to get the best recommendations possible.
      </Text>
      <Text color="text" p="2">
        While we strive to make our recommendations as accurate as possible, please note that the AI may not always provide an exact match for your query. Additionally, our model may not contain information about all books, which could result in some recommendations being missed. However, we are continuously improving our model to provide better results for our users.
      </Text>
      <Text color="text" p="2">
        As an AI-powered site, there may be occasional instances where the recommendations provided by {title} seem weird or even offensive. Please keep in mind that any recommendations generated by the AI do not reflect our personal opinions.
      </Text>
      <Text color="text" p="2">
        We hope you enjoy using {title} and find your next favorite book or audiobook with ease.
      </Text>
    </Container>
    <Flex 
          bg="heading"
          justifyContent="center"
          alignItems="center"
          h="2em"
          width="100%"
          marginTop="2em"
          >
          <Link href="/"><Text fontSize="xs" paddingRight="3em">Home</Text></Link>
    </Flex>
    </Box>
  );
};
  
export default About;