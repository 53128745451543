import { forwardRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { IconButton, Container, Center, Flex, Button, Textarea, Input, Text, Box, Link, Heading, Stack, Skeleton } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import ResizeTextarea from "react-textarea-autosize";
import InfiniteScroll from 'react-infinite-scroller';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import 'poppins-font';

const maxRecommendations = 20;
const maxRetries 			 	 = 2;
const api = (window.location.hostname === "localhost")? "http://localhost:5000" : "https://fplamybingtgygmrocv6rpzwjy0hevld.lambda-url.eu-north-1.on.aws";

const example_prompts = [
	"Where the main character has an unusual hobby or interest",
	"A story that takes place in a world where magic exists but is illegal",
	"Features a talking animal as a main character",
	"A book where the setting is a post-apocalyptic version of a major city",
	"Explore the concept of time travel in a unique way",
	"A book that is written entirely in verse",
	"The main character has a phobia or anxiety disorder",
	"A book that is based on a true story, but the truth is stranger than fiction",
	"It should feature a group of characters who all have superpowers but are not superheroes",
	"Narrated by a non-human character, like a ghost or an alien",
	"A fictional story that make you take it slow and appreciate life",
	"Self-help book for dog lovers",
	"I am looking for a book that will make me question my beliefs and challenge my worldview",
	"I want to read a book that will give me a glimpse into a culture or way of life that I'm not familiar with",
	"Can you recommend a book that features an unreliable narrator?",
	"It should takes place entirely within the confines of a single room",
	"Fantasy books taking place underwater",
	"Fantasy mystery books like Harry Potter",
	"Crime books for podcast addicts",
	"Sci fi by POC authors",
];

const fixed_prompts = [
	"I want to survive the apocalypse again.",
	"Dark and haunting romance.",
	"Very obscure fantasy books that make me laugh.",
	"I am looking for other suspensful mystery and thriller books like The Silent Patient.",
	"Non-fiction books about the rise and fall of the dinosaurs",
];

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
}

function animateWrite(query, placeholder, setPlaceholder) {
	if (query.length)
		return "Refine query?";

	if (placeholder) {

	}
}

export const AutoResizeTextarea = forwardRef((props, ref) => {
  return (
    <Textarea
      minH="unset"
      overflow="hidden"
      w="100%"
      resize="none"
      ref={ref}
      minRows={1}
      as={ResizeTextarea}
      transition="height none"
      {...props}
    />
  );
});

function ItemList({ items, query }) {
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		const getBookInformation = async (item) => {
			try {
				item.informationLoading = true;

				const body = {"title": item.title, "author": item.author};
				const response = await fetch(api + "/get_information", {
					method: "POST",
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(body),
				});

				if (!response.ok)
					throw new Error("Network error");

				const data = await response.json();

				if (!("info" in data) || !data["info"])
					throw new Error("Data error");

				item["info"  ] = data["info"];
				item["href"  ] = data["href"];
				item["cover" ] = data["cover"];
				item["title" ] = data["title"];
				item["author"] = data["author"];
				item["series"] = data["series"];
			}
			catch {
				item.info  = false;
				item.cover = "EMPTY";
			}

			forceUpdate();
		}

		const syncBookInformation = async () => {
			const tasks = [];
			for (const item of items) {
				if (!item.informationLoading && !("info" in item)) {
					tasks.push(getBookInformation(item));
				}
			}
			Promise.all(tasks);
		}

		syncBookInformation();
	}, [items, forceUpdate]);

	useEffect(() => {
		const getSummary = async () => {
			for (const item of items) {
				if (!item.summaryTries) {
					item.summaryTries = 0;
				}

				if ("summary" in item && item["summary"] === "")
					item["summary"] = " ";

				if (!("summary" in item) && !item.loadingSummary && item["info"]) {
					item.loadingSummary = true;

					try {
						//throw Error("Remove reason");

						const body = {"title": item._title, "author": item._author};
						const response = await fetch(api + "/get_summary", {
							method: "POST",
							headers: {'Content-Type': 'application/json'},
							body: JSON.stringify(body),
						});

						if (!response.ok)
							throw new Error("Network error");

						item.summary = await response.text();
						item.summary = item.summary.replace(/"/g, '');

						if (!item.summary)
							throw new Error("AI error");

						forceUpdate();
					}
					catch (error) {
						item.loadingSummary = false;
						item.summaryTries += 1;

						if (item.summaryTries >= 1) {
							item.summary = " ";
							forceUpdate();
						}

						throw error;
					}
				}
			};
		};

		getSummary();
	}, [items, query, forceUpdate]);

	return (
	  <Box as="ul" listStyleType="none" p="0">
	    {items.filter((item) => {return !("info" in item) || item["info"]}).map((item) => (
	      <Box as="li" key={item.title} mb={4} display="flex" flexDirection="row" marginBottom="1em">
	      	{item.cover && item.cover === "EMPTY" && (<Link href={item.href} target="_blank">
		        <Box as="div" borderRadius="md" mb={4} marginRight="1em" _hover={{ filter: "brightness(80%)" }}
		        	style={{
									backgroundImage: `url(${item.cover})`,
									backgroundPosition: "left top",
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									minWidth: "150px",
									minHeight: "150px",
								}}>
		        </Box>
	        </Link>)}
	      	{item.cover && item.cover !== "EMPTY" && (<Link href={item.href} target="_blank">
		        <Box as="div" borderRadius="md" mb={4} marginRight="1em" _hover={{ filter: "brightness(80%)" }}
		        	style={{
									backgroundImage: `url(${item.cover})`,
									backgroundPosition: "left top",
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									minWidth: "150px",
									minHeight: "150px",
								}}>
		        </Box>
	        </Link>)}
	        {!item.cover && (<Skeleton minWidth="150px" minHeight="150px" marginBottom="1em" marginRight="1em"/>)}
	        <Box width="100%">
	          <Heading as="h3" size="md" mb={1} color="text">
	            <Link href={item.href} fontWeight="bold" target="_blank">
	              {item.title}
	            </Link>
	          </Heading>
	          {item.series && (
	            <Text mb={1} fontSize="sm" color="text">
	              {item.series}
	            </Text>
	          )}
	          <Text mb={1} fontSize="sm" fontStyle="italic" color="text">
	            By {item.author}
	          </Text>
	          {!item.summary && (
	             <Box>
	              <Skeleton height="1em" marginBottom="0.5em"/>
	              <Skeleton height="1em" marginBottom="0.5em"/>
	             </Box>)}
	          {item.summary && (
	            <Text mb={1} fontSize="sm" color="text">
	              {item.summary}
	            </Text>)}
	        </Box>
	      </Box>
	    ))}
	  </Box>
	);
}

function MetaTags(query) {
	const [meta, setMeta] = useState({"title": "Book recommendations using AI", "desc": "What to read next? Discover new books from any search."});

	const queryMeta = async(query) => {
		const query_str = query.join("\n");
		const response  = await fetch(api + "/get_meta?q=" + query_str);
		
		if (!response.ok)
			throw new Error("Network error");

		const data = await response.json();

		setMeta({...meta, ...data});
	}

	useEffect(() => {
		queryMeta(query);
	}, [query]);

	// title, desc, url, card

	return (
		<>
			{meta['title'] && <title>{meta['title']}</title>}
			{meta['title'] && <meta property="og:title"      content={meta['title']}/>}
			{meta['title'] && <meta property="twitter:title" content={meta['title']}/>}

			{meta['desc'] && <meta name="description" content={meta['desc']}/>}
			{meta['desc'] && <meta property="og:description"      content={meta['desc']}/>}
			{meta['desc'] && <meta property="twitter:description" content={meta['desc']}/>}

			{meta['url'  ] && <meta property="twitter:image" content={meta['url']}/>}
			{meta['url'  ] && <meta property="og:image"      content={meta['url']}/>}

			{meta['card' ] && <meta property="twitter:card"  content="summary_large_image"/>}

			<meta property="og:url" content={window.location.href}/>
		</>
	);
}


function Search() {
	const defaultPlaceholder = example_prompts[Math.floor(Math.random() * example_prompts.length)];

	const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
	const [inputValue, setInputValue] = useState("");
	const [query, setQuery]         = useState([]);

	const [recommendations, setRecommendations] = useState([]);
	const [shouldLoad, setShouldLoad] = useState(false);
	const [retries, setRetries] 		  = useState(0);

	const [isLoading , setIsLoading]  = useState(false);
	const [abortControllers, setAbortControllers] = useState([]);

	const [searchParams, setSearchParams] = useSearchParams({});

	// Set default query
	useEffect(()=> {
		const queryParams = new URLSearchParams(window.location.search);
		if (queryParams.get("q")) {
			setQuery(queryParams.get("q").split("\n"));
			setPlaceholder("Refine or clarify query?");
			setRecommendations([]);
			setShouldLoad(true);
			setIsLoading(false);
			setRetries(0);
			setAbortControllers([]);
		}
	}, []);

	const valid_query = () => {
		return inputValue || !query.length;
	}

	const get_query_value = () => {
		return (inputValue)? inputValue : placeholder;
	}

	/*
		Query
	*/
	const submitQuery = async (e) => {
		if (e) {
			e.preventDefault();
		}

		if (valid_query()) {
			const iv = get_query_value().split("\n");
			const nq = [...query, ...iv];

			setQuery(nq);
			setPlaceholder("Refine or clarify query?");
			setRecommendations([]);
			setShouldLoad(true);
			setIsLoading(false);
			setRetries(0);
			abortControllers.forEach(controller => {
				controller.abort();
			});
			setAbortControllers([]);
			if (nq.length) {
				setSearchParams({q: nq.join("\n")});
			}
		}

		setInputValue("");
	};

	const clearQuery = async (e) => {
		setPlaceholder(defaultPlaceholder);
		setQuery([]);
		setRecommendations([]);
		setShouldLoad(false);
		setIsLoading(false);
		setRetries(0);
		abortControllers.forEach(controller => {
			controller.abort();
		});
		setAbortControllers([]);
		setSearchParams();
	}

	/*
		Get recommendations
	*/

const updateRetry = () => {
	if (retries >= maxRetries) {
		setShouldLoad(false);
	}
	setRetries(retries + 1);
}

const queryApi = async () => {
	const query_str = query.join("\n");

	if (recommendations.length >= maxRecommendations) {
		setShouldLoad(false);
	}

	try {
		if (query_str && !isLoading && shouldLoad && recommendations.length < maxRecommendations) {
			setIsLoading(true);

			const body = {"query": query_str, "memory": recommendations};

			const abortController = new AbortController();
			setAbortControllers([...abortControllers, abortController]);

			const response = await fetch(api + "/get_item", {
				method: "POST",
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(body),
				signal: abortController.signal
			});

			if (!response.ok)
				throw new Error("Network error");

			if (!abortController.signal.aborted) {
					const data  = await response.json();
					const books = [];

					for (let item of data) {
						if (item.title && item.author) {
							if (!recommendations.some(itemB => itemB.title === item.title)) {
								books.push(item);
							}
						}
					}

					if (books.length) {
						setRetries(0);
					}
					else {
						updateRetry();
					}

					setRecommendations([...recommendations, ...books]);
				}

				setIsLoading(false);
			}
		}
		catch (error) {
			updateRetry();
			setIsLoading(false);
		}
	}

		const checkStatus = (query, recommendations, shouldLoad) => {
			if (shouldLoad || !query.length)
				return;
			if (recommendations.length >= 15)
				return (<Text width="80%">Did not find what you were searching for? Try refining the query.</Text>);
			if (recommendations.length)
				return (<Text width="80%">We could not find any additional suitable recommendations.</Text>);
			return (<Text width="80%">We were unfortunately unable to find any suitable recommendations for this query.</Text>);
		}

		return (
      	<Box bg="background" width="100%" minH="100vh">
      		<Helmet>
      			{MetaTags(query)}
      		</Helmet>
	      	<Container minW="sm" width="70%" centerContent minH="calc(100vh - 4em)">
		      	<Heading as="h2" paddingTop="2em" paddingBottom="1em" color="heading">Readivate</Heading>
		      	<Heading as="h1" size="md" paddingBottom="0.5em" color="text">Get book recommendations powered by ChatGPT</Heading>
		      	<Text textAlign="center" width="80%" minW="280px" color="text" paddingBottom="2em">Search for anything or get inspired by our example prompts in the box below.</Text>
						<Box width="100%" borderRadius="md" bg="white" marginBottom="2em" padding="1em">
							{query.map((item, index) => (
								<Text key={index} p="1" color="text">
									{item}
								</Text>
							))}
							<Flex width="100%">
								<AutoResizeTextarea
									variant="flushed"
									focusBorderColor="heading"
									value={inputValue}
									placeholder={placeholder}
									sx={{ outline: "none" }}
									onChange={(e) => {e.preventDefault(); setInputValue(e.target.value)}}
									onKeyDown={(e) => {if (e.key === "Enter" && !e.shiftKey) submitQuery(e)}}
									color="text"
								/>
								{ valid_query() && (<IconButton alignSelf="flex-end" aria-label="Send query" variant="ghost" onClick={submitQuery} colorScheme="blue" icon={<SearchIcon color="heading"/>}/>)}
								{!valid_query() && (<IconButton alignSelf="flex-end" aria-label="New query" variant="ghost" onClick={clearQuery} colorScheme="blue" icon={<CloseIcon color="heading"/>}/>)}
							</Flex>
						</Box>
						<InfiniteScroll
							pageStart={0}
							loadMore={queryApi}
							hasMore={shouldLoad}
							style={{
								width: "100%",
							}}
							loader={
								<Box>
									<Skeleton height='150px' marginBottom="1em" borderRadius="md"/>
									<Skeleton height='150px' borderRadius="md"/>
								</Box>
							}>
							{ItemList({items: recommendations, query: query})}
						</InfiniteScroll>
						{checkStatus(query, recommendations, shouldLoad)}
						{!shouldLoad && !query.length && (<Box marginLeft="1em" marginBottom="2em" width="100%">
							<Text>- Readivate only recommends audiobooks for now. Normal books to be added soon.</Text>
							<Text>- Tired of bestsellers? Try asking for obscure books.</Text>
						</Box>)}
						{!shouldLoad && !query.length && (<Box marginLeft="1em" width="100%">
							<Text marginBottom="0.1em">Examples</Text>
							{fixed_prompts.map((item) => (
								<Link href={"/?q=" + item}><Text fontSize="sm" marginBottom="0.5em" textAlign="left" marginLeft="1em">{item}</Text></Link>
							))}
						</Box>)}
				</Container>
				<Flex 
					bg="heading"
					justifyContent="center"
      		alignItems="center"
      		h="2em"
      		width="100%"
      		marginTop="2em"
      		>
					<Link href="/about"><Text fontSize="xs" paddingRight="3em">About</Text></Link>
					<Link href="/terms_conditions"><Text fontSize="xs" paddingRight="3em">Terms and Conditions</Text></Link>
					<Link href="/privacy_policy"><Text  fontSize="xs" paddingRight="3em">Privacy Policy</Text></Link>
					<Link href="/prompts"><Text  fontSize="xs" paddingRight="3em">Prompts</Text></Link>
				</Flex>
			</Box>
			);
	}

export default Search;
