import { useState, useEffect} from "react";
import {Heading, Input, Container, Box, Button, Flex, Link, Text } from '@chakra-ui/react';

const api = (window.location.hostname === "localhost")? "http://localhost:5000" : "https://fplamybingtgygmrocv6rpzwjy0hevld.lambda-url.eu-north-1.on.aws";

function Prompts() {
	const [items, setItems] = useState([]);

	const get_prompts = async () => {
		const response = await fetch(api + "/get_prompts");

		if (!response.ok)
			throw new Error("Network error");
		setItems(await response.json());
	}

	useEffect(() => {
		get_prompts();
	}, [items]);

	return (
      	<Box bg="background" width="100%" minH="100vh">
	      	<Container minW="sm" width="70%" minH="calc(100vh - 4em)">
	      		<Heading paddingTop="1em" paddingBottom="1em">Prompts</Heading>
	      		{items.map((item) => (
	      			<Link href={item["url"]}><Text paddingBottom="1em">{item["query"]}</Text></Link>
	      		))}
	      	</Container>
		     <Flex 
	          bg="heading"
	          justifyContent="center"
	          alignItems="center"
	          h="2em"
	          width="100%"
	          marginTop="2em"
	          >
	          <Link href="/"><Text fontSize="xs" paddingRight="3em">Home</Text></Link>
	    	</Flex>
	    </Box>);
}

export default Prompts;
